import { useMutation } from "@tanstack/react-query";
import { toast } from "sonner";

import sessionConfig from "@/config/session-config";
import serverApi from "@/lib/server-api";
import { StoreType } from "@/types";
import { LoginResponse } from "@/types/login";
import { UserType } from "@/types/user";
import { getErrorMessage } from "@/utils";

type Props = {
  password: string;
  new_password: string;
};
type updateUserType = Partial<UserType & Props>;
const loginUser = async (payload: { email: string; password: string }) => {
  const { data }: { data: LoginResponse } = await serverApi.post(
    "/auth/login",
    payload,
  );
  return data;
};

const forgetPassword = async (payload: { email: string }) => {
  await serverApi.post("/auth/forget-password", payload);
  localStorage.setItem(sessionConfig.tempEmail, payload.email);
};

const verifyCode = async (payload: { email: string; code: string }) => {
  const { data }: { data: { user: UserType; stores: StoreType[] } } =
    await serverApi.post("/auth/verify-code", payload);
  return data;
};

const signupUser = async (payload: { email: string; password: string }) => {
  const { data }: { data: { user: UserType } } = await serverApi.post(
    "/auth/signup",
    payload,
  );
  return data.user;
};
const updateUser = async (payload: updateUserType) => {
  const { user_id, ...rest } = payload;
  const { data }: { data: { user: UserType } } = await serverApi.put(
    `/auth/${user_id}`,
    { ...rest },
  );
  return data.user;
};

export const useLoginUser = () => {
  return useMutation({
    mutationFn: async (payload: { email: string; password: string }) =>
      await loginUser(payload),
    onError: (error) => {
      toast.error(getErrorMessage(error));
    },
  });
};
export const useForgetPassword = () => {
  return useMutation({
    mutationFn: async (payload: { email: string }) =>
      await forgetPassword(payload),
    onError: (error) => {
      toast.error(getErrorMessage(error));
    },
  });
};
export const useVerifyCode = () => {
  return useMutation({
    mutationFn: async (payload: { email: string; code: string }) =>
      await verifyCode(payload),
    onError: (error) => {
      toast.error(getErrorMessage(error));
    },
  });
};

export const useSignupUser = () => {
  return useMutation({
    mutationFn: async (payload: { email: string; password: string }) =>
      await signupUser(payload),
    onError: (error) => {
      toast.error(getErrorMessage(error));
    },
  });
};
export const useUpdateCurrentUser = () => {
  return useMutation({
    mutationFn: async (payload: updateUserType) => await updateUser(payload),
    onError: (error) => {
      toast.error(getErrorMessage(error));
    },
  });
};
