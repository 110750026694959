import { z } from "zod";

export const UserLoginSchema = z.object({
  email: z.string().email(),
  password: z
    .string()
    .min(6, "Password Length must be greater than 6 characters"),
});

export const ForgetPasswordSchema = z.object({
  email: z.string().email(),
});
export const VerifyCodeSchema = z.object({
  email: z.string().email(),
  code: z
    .string()
    .min(4, "Code must be 4 characters")
    .max(4, "Code must be 4 characters"),
});

export const ResetPasswordSchema = z
  .object({
    new_password: z.string().min(8, "At least 8 characters"),
    confirm_password: z.string().min(8, "At least 8 characters"),
  })
  .refine((data) => data.new_password === data.confirm_password, {
    message: "Passwords do not match",
    path: ["confirm_password"],
  });

export const UserSignupSchema = z.object({
  email: z.string().email(),
  password: z
    .string()
    .min(6, "Password Length must be greater than 6 characters"),
});
