"use client";

import { cn, Input as NextInput, InputProps } from "@nextui-org/react";
import { Eye, EyeSlash } from "iconsax-react";
import { forwardRef, useState } from "react";
type Props = {
  isPassword?: boolean;
};
const Input = forwardRef<HTMLInputElement, InputProps & Props>(function Input({
  variant = "bordered",
  labelPlacement = "outside",
  color = "primary",
  radius = "md",
  size = "lg",
  classNames = {
    base: "",
    label: "!text-sm !text-[#808080]"
  },
  isPassword = false,
  ...otherProps
}, ref) {
  const [show, setShow] = useState(false);
  return <NextInput variant={otherProps?.isDisabled ? "faded" : variant} labelPlacement={labelPlacement} color={color} radius={radius} size={size} ref={ref} classNames={{
    ...classNames,
    inputWrapper: cn(classNames.inputWrapper, {
      "pl-4 pt-0 pb-1 min-h-[4.7rem]": labelPlacement === "inside"
    }),
    innerWrapper: cn(classNames.innerWrapper, {
      "pb-2": labelPlacement === "inside"
    }),
    label: cn(classNames.label, {
      "pb-[0.25rem]": labelPlacement === "inside"
    })
  }} endContent={!isPassword ? otherProps.endContent : show ? <EyeSlash size="23" color="#999999" onClick={() => setShow(!show)} /> : <Eye size="23" color="#999999" onClick={() => setShow(!show)} />} isInvalid={!!otherProps.errorMessage} {...otherProps} type={isPassword && show ? "text" : otherProps.type} />;
});
export default Input;