"use client";

import { Image, ImageProps } from "@nextui-org/react";
import React, { useState } from "react";
import { LOGO_URL_BLACK, LOGO_URL_BLUE, LOGO_URL_WHITE } from "@/config/constant";
type LogoProps = ImageProps & {
  white?: boolean;
  blue?: boolean;
};
const PerDiemLogo = ({
  white = false,
  blue = true,
  width = 24,
  height = 24,
  radius = "none",
  ...otherProps
}: LogoProps) => {
  const defaultLogo: string = white ? LOGO_URL_WHITE : blue ? LOGO_URL_BLUE : LOGO_URL_BLACK;
  const [logo] = useState<string | null>(defaultLogo);
  if (!logo) {
    return null;
  }
  return <div data-sentry-component="PerDiemLogo" data-sentry-source-file="perdiem-logo.tsx">
      <Image width={width} height={height} src={String(logo)} alt="Per Diem Logo" radius={radius} {...otherProps} data-sentry-element="Image" data-sentry-source-file="perdiem-logo.tsx" />
    </div>;
};
export default PerDiemLogo;