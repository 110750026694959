import { Image } from "@nextui-org/react";
import React from "react";
import AuthImage1 from "@/assets/images/auth/auth-image-1.png";
import AuthImage2 from "@/assets/images/auth/auth-image-2.png";
export default function Authlayout({
  children
}: {
  children: React.ReactNode;
}) {
  return <div className="flex size-full w-screen flex-1 flex-col items-center justify-center gap-24 md:flex-row md:bg-surface-default" data-sentry-component="Authlayout" data-sentry-source-file="auth-layout.tsx">
      <div className="hidden w-full justify-start md:flex ">
        <Image src={AuthImage1.src} width={500} height={500} alt="image" data-sentry-element="Image" data-sentry-source-file="auth-layout.tsx" />
      </div>
      <div>{children}</div>
      <div className="hidden w-full justify-end md:flex ">
        <Image src={AuthImage2.src} width={500} height={500} alt="image" data-sentry-element="Image" data-sentry-source-file="auth-layout.tsx" />
      </div>
    </div>;
}