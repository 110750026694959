import { useMutation } from "@tanstack/react-query";
import { toast } from "sonner";

import serverApi from "@/lib/server-api";
import { StoreType, UserType } from "@/types";
import { getErrorMessage } from "@/utils";

type Response = {
  user: UserType;
  new_user: boolean;
  stores: StoreType[];
};
const googleSignin = async (payload: { token: string }) => {
  const { data }: { data: Response } = await serverApi.post(
    "/auth/google-signin",
    payload,
  );
  return data;
};

export const useGoogleSignin = () => {
  return useMutation({
    mutationFn: (payload: { token: string }) => googleSignin(payload),
    onError: (error: unknown) => {
      toast.error(getErrorMessage(error));
    },
  });
};
