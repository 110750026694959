const sessionConfig = {
  // local storage keys
  user: "user",
  store: "store",
  stores: "stores",
  users: "users",
  activeStore: "active_store",
  readNotifications: "read_notifications",
  tempEmail: "temp_email", // persisted on forgot password flow, in case user needs to resend request

  // server session keys
  token: "token",
  isLoggedIn: "is_logged_in",
  isAdmin: "is_admin",
  storeHasAccess: "store_has_access",
  posConnectionSource: "pos_connection_source",
  trialExpired: "trial_expired",
  stripeSessionId: "stripe_session_id",
  newSignup: "new_store_signup",
};
export default sessionConfig;
