"use client";

import { Button as NextButton, ButtonProps } from "@nextui-org/react";
import { forwardRef } from "react";
const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button({
  radius = "lg",
  color = "primary",
  isDisabled = false,
  ...otherProps
}, ref) {
  return <NextButton ref={ref} isDisabled={isDisabled} color={isDisabled ? "default" : color} radius={radius} {...otherProps} />;
});
export default Button;